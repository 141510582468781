import React from 'react'
import styled from 'styled-components'
import { Wrapper } from './Section'

const Header = styled.header`
	h1 {
		margin: 0;
		padding-top: ${props => props.theme.spacing.v.sm};
		padding-bottom: ${props => props.theme.spacing.v.sm};
	}
`

export default props => (
	<Header {...props}>
		<Wrapper {...props} wide>
			{props.children}
		</Wrapper>
	</Header>
)
