// import React from 'react'
import styled from 'styled-components'

export default styled.p`
	text-align: ${props => {
		if (props.align === 'right' || (props.action && !props.align)) {
			return 'right'
		} else if (props.align === 'center') {
			return 'center'
		}
		return 'left'
	}};
	padding-top: ${props => (props.action ? props.theme.spacing.v.sm : 0)};
	margin-bottom: ${props => (props.dense ? 0 : '1.45rem')};
`
