import React from 'react'
import styled from 'styled-components'

const ButtonBase = styled.button`
	background: ${props => {
		if (props.ghost) {
			return 'none'
		} else if (props.flat) {
			if (props.accent) {
				return props.theme.btn.accent.flat.bg
			} else if (props.primary) {
				return props.theme.btn.primary.flat.bg
			} else {
				return props.theme.btn.default.flat.bg
			}
		}
		return 'none'
	}};
	color: ${props => {
		if (props.ghost) {
			if (props.accent) {
				return props.theme.btn.accent.ghost.fg
			} else if (props.primary) {
				return props.theme.btn.primary.ghost.fg
			} else {
				return props.theme.btn.default.ghost.fg
			}
		} else if (props.flat) {
			if (props.accent) {
				return props.theme.btn.accent.flat.fg
			} else if (props.primary) {
				return props.theme.btn.primary.flat.fg
			} else {
				return props.theme.btn.default.flat.fg
			}
		} else if (props.link) {
			if (props.accent) {
				return props.theme.btn.accent.link.fg
			} else if (props.primary) {
				return props.theme.btn.primary.link.fg
			} else {
				return props.theme.btn.default.link.fg
			}
		}
	}};
	border-color: ${props => {
		if (props.ghost) {
			if (props.accent) {
				return props.theme.btn.accent.ghost.fg
			} else if (props.primary) {
				return props.theme.btn.primary.ghost.fg
			} else {
				return props.theme.btn.default.ghost.fg
			}
		} else if (props.flat) {
			if (props.accent) {
				return props.theme.btn.accent.flat.bg
			} else if (props.primary) {
				return props.theme.btn.primary.flat.bg
			} else {
				return props.theme.btn.default.flat.bg
			}
		}
		return 'none'
	}};
	border-width: ${props => props.theme.btn.borderWidth};
	border-radius: ${props => props.theme.btn.radius};
	border-style: solid;
	cursor: pointer;
	outline: none;
	display: inline-block;
	text-decoration: none;
	font-size: ${props =>
		props.big ? props.theme.btn.fontSizeMed : props.theme.btn.fontSize};

	padding: ${props =>
		props.big ? props.theme.btn.paddingMed : props.theme.btn.padding};

	@media (min-width: 600px) {
		padding: ${props =>
			props.big ? props.theme.btn.paddingBig : props.theme.btn.padding};
		font-size: ${props =>
			props.big ? props.theme.btn.fontSizeBig : props.theme.btn.fontSize};
	}
`

const ButtonLink = ButtonBase.withComponent('a')

const Wrapper = styled.span`
	display: flex;
	align-items: center;
`

const Button = props => {
	const PreIcon = props.preIcon || 'span'
	const PostIcon = props.postIcon || 'span'

	const marginRight = props.postIcon ? '1rem' : 0
	const marginLeft = props.preIcon ? '1rem' : 0
	const labelStyle = { marginLeft, marginRight }

	const FinalButton = props.link ? ButtonLink : ButtonBase

	return (
		<FinalButton {...props}>
			<Wrapper>
				<PreIcon />
				<span style={labelStyle}>{props.children}</span>
				<PostIcon />
			</Wrapper>
		</FinalButton>
	)
}

Button.defaultProps = {
	link: false,
	preIcon: null,
	postIcon: null,
}

export default Button
