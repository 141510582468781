import React from 'react'
import Helmet from 'react-helmet'

import Document from '../layouts/Document'
import ContainerWrapper from '../layouts/ContainerWrapper'
import Header from '../layouts/Header'
import { Hero } from '../components/Hero'
import PageTitle from '../layouts/PageTitle'
import Section from '../layouts/Section'
import SectionTitle from '../layouts/SectionTitle'
import Text from '../components/Text'
import { Link } from 'gatsby'

const NotFoundPage = () => (
	<Document>
		<Helmet>
			<title>Error</title>
		</Helmet>
		<ContainerWrapper>
			<Header>
				<Hero>
					<PageTitle>Página no encontrada</PageTitle>
				</Hero>
			</Header>
			<Section>
				<SectionTitle>La página que buscás no existe :(</SectionTitle>
				<Text>Te recomendamos volver al <Link to='/'>Inicio</Link>.</Text>
			</Section>
		</ContainerWrapper>
	</Document>
)

export default NotFoundPage
