import React from 'react'
import styled from 'styled-components'
import headerImgSrc from '../images/startup-1920.jpg'
import { FiPlus } from 'react-icons/fi'

const HeroBase = styled.div`
	background: ${props =>
		props.home
			? `url(${headerImgSrc})`
			: `linear-gradient(${props.theme.color.accentAlpha}, ${
					props.theme.color.accentAlpha
			  }), url(${headerImgSrc})`};
	background-repeat: no-repeat;
	background-position: center center;
`

const Wrapper = styled.div`
	margin: auto;
	max-width: ${props => props.theme.spacing.containerWidth};
	padding-top: ${props => props.theme.spacing.v.sm};
	padding-bottom: ${props => props.theme.spacing.v.sm};
	padding-left: ${props => props.theme.spacing.h.sm};
	padding-right: ${props => props.theme.spacing.h.sm};

	@media (min-width: ${props => props.theme.spacing.containerWidth}) {
		padding-left: 0;
		padding-right: 0;
		padding-top: ${props => props.theme.spacing.v.std};
		padding-bottom: ${props => props.theme.spacing.v.std};
	}
`

export const Hero = props => (
	<HeroBase {...props}>
		<Wrapper {...props}>{props.children}</Wrapper>
	</HeroBase>
)

export const HeroList = styled.ul`
	list-style: none;
	margin: 0;

	color: ${props => props.theme.color.black};
	max-width: 100%;
	font-size: 1.1rem;

	@media (min-width: ${props => props.theme.spacing.containerWidth}) {
		max-width: 46%;
		font-size: 1.2rem;
	}

	@media (min-width: 600px) and (max-width: ${props =>
			props.theme.spacing.containerWidth}) {
		max-width: 66%;
		font-size: 1.2rem;
	}
`

const ListItemBase = styled.li`
	background-color: ${props => props.theme.color.blackAlpha};
	color: ${props => props.theme.color.white};
	margin: 0;
	padding: 5.6px;
	display: flex;
	align-items: center;
	margin-bottom: ${props => (props.last ? 0 : props.theme.spacing.v.smr)};

	@media (min-width: ${props => props.theme.spacing.containerWidth}) {
		margin-bottom: ${props => (props.last ? 0 : props.theme.spacing.v.sm)};
	}

	@media (min-width: 600px) and (max-width: ${props =>
			props.theme.spacing.containerWidth}) {
		margin-bottom: ${props => (props.last ? 0 : props.theme.spacing.v.sm)};
	}
`

const IconWrapper = styled.span`
	font-size: 1.8rem;
	display: flex;
	justify-content: center;
	margin-right: 5.6px;
`

export const HeroListItem = props => {
	return (
		<ListItemBase last={props.last}>
			<IconWrapper>
				<FiPlus />
			</IconWrapper>
			{props.children}
		</ListItemBase>
	)
}

export const HeroAction = styled.div`
	text-align: right;
`
