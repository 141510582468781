import React from 'react'
import Helmet from 'react-helmet'
import styled from 'styled-components'

import Document from '../layouts/Document'
import ContainerWrapper from '../layouts/ContainerWrapper'
import Header from '../layouts/Header'
import PageTitle from '../layouts/PageTitle'
import Section from '../layouts/Section'
import SectionTitle from '../layouts/SectionTitle'
import SectionSubTitle from '../layouts/SectionSubTitle'

import { Hero } from '../components/Hero'
import Text from '../components/Text'
import Icon from '../components/Icon'
import { List, ListItemCheck } from '../components/List'

import { FiSearch, FiUserCheck, FiTrendingUp } from 'react-icons/fi'

const Grid2 = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: ${props => props.theme.spacing.v.sm};

	@media (min-width: 600px) {
		grid-template-columns: 1fr 1fr;
		grid-gap: 6%;
	}
`

const Grid3 = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: ${props => props.theme.spacing.v.sm};

	@media (min-width: 770px) {
		grid-template-columns: 1fr 1fr 1fr;
		grid-gap: 6%;
	}
`

export default () => (
	<Document>
		<Helmet>
			<title>Servicios</title>
			<meta
				name="description"
				content="Contamos con un equipo de trabajo especializado en el reclutamiento y la selección de perfiles idóneos."
			/>
		</Helmet>
		<ContainerWrapper>
			<Header>
				<Hero>
					<PageTitle>Servicios</PageTitle>
				</Hero>
			</Header>

			<Section>
				<SectionTitle id="seleccion-de-personal">
					<Icon pre={FiSearch} />
					<span>Selección de personal</span>
				</SectionTitle>
				<Grid3>
					<Text>
						Contamos con un equipo de trabajo especializado en el
						reclutamiento y la selección de perfiles idóneos.
					</Text>
					<Text>
						Ofrecemos búsquedas a medida, entendiendo las
						necesidades y tiempos de nuestros clientes.
					</Text>
					<Text>
						Nos especializamos en servicios de búsqueda y selección
						de personal de puestos gerenciales, mandos medios,
						puestos operativos y perfiles IT.
					</Text>
				</Grid3>
				<Text>Etapas</Text>
				<List listStyle="ol">
					<li>Análisis de las necesidades</li>
					<li>Reclutamiento de candidatos</li>
					<li>Preselección</li>
					<li>Entrevistas</li>
					<li>Pruebas técnicas</li>
					<li>Valoración y decisión junto al cliente</li>
					<li>Incorporación</li>
					<li>Seguimiento</li>
				</List>
				<Text>Ofrecemos garantia en todas nuestras búsquedas.</Text>
			</Section>

			<Section>
				<SectionTitle id="evaluaciones">
					<Icon pre={FiUserCheck} />
					<span>Evaluaciones</span>
				</SectionTitle>
				<Grid2>
					<div>
						<SectionSubTitle>Psicotécnicos</SectionSubTitle>
						<Text>
							Permite conocer tanto el nivel intelectual del
							candidato como los rasgos de personalidad,
							fortalezas, debilidades, potencialidades, etc,
							asegurándonos así congruencia entre el candidato,
							las necesidades del puesto y la cultura
							organizacional.
						</Text>
						<Text>
							Nuestros informes se caracterizan por ser claros y
							precisos para facilitar su interpretación.
						</Text>
					</div>

					<div>
						<SectionSubTitle>Socioambientales</SectionSubTitle>
						<Text>
							Evaluamos el medio ambiente en el que el candidato
							se desenvuelve, la conformación e interacción de su
							grupo familiar.
						</Text>
					</div>

					<div>
						<SectionSubTitle>
							Evaluaciones de desempeño
						</SectionSubTitle>
						<Text>
							Mide el desempeño y las calificaciones del empleado
							en función de los requerimientos del puesto. Esta
							herramienta es muy utilizada para tomar decisiones
							respecto de promociones internas, capacitaciones,
							compensaciones.
						</Text>
					</div>

					<div>
						<SectionSubTitle>Clima Laboral</SectionSubTitle>
						<Text>
							Nos acerca a la percepción que tiene el personal
							respecto de su trabajo, area, jefe y empresa. A
							partir del relevamiento general del clima laboral
							(factores motivantes y desmotivantes) podremos
							realizar un diagnóstico organizacional
							(identificación de necesidades) y a partir de este
							ofrecer un plan de acción.
						</Text>
					</div>
				</Grid2>
			</Section>

			<Section>
				<SectionTitle id="startup">
					<Icon pre={FiTrendingUp} />
					<span>Start Up – RRHH</span>
				</SectionTitle>
				<Grid2>
					<Text>
						Ofrecemos este servicios para clientes que aún no hayan
						desarrollado el área de RRHH o bien estén iniciando sus
						actividades en el mercado.
					</Text>
					<Text>
						Nuestra tarea consiste en acompañar a nuestros clientes
						en el nacimiento, desarrollo y crecimiento del área.
					</Text>
				</Grid2>
				<Text>Diseñamos planes a medida donde implementamos:</Text>
				<List>
					<ListItemCheck>Administración de personal</ListItemCheck>
					<ListItemCheck>Control de ausentismo</ListItemCheck>
					<ListItemCheck>Armado de legajos</ListItemCheck>
					<ListItemCheck>Documentación legal</ListItemCheck>
					<ListItemCheck>Armado de organigrama</ListItemCheck>
					<ListItemCheck>Descripción de puestos</ListItemCheck>
					<ListItemCheck>Detección de necesidades</ListItemCheck>
					<ListItemCheck>Programas de capacitación</ListItemCheck>
					<ListItemCheck>Evaluación de desempeño</ListItemCheck>
					<ListItemCheck>Otras</ListItemCheck>
				</List>
			</Section>
		</ContainerWrapper>
	</Document>
)
