import React from 'react'

class HideFromBot extends React.Component {
	constructor(props) {
		super(props)

		this.state = { counter: 0, replaced: false }

		this.addEventListeners = this.addEventListeners.bind(this)
		this.removeEventListeners = this.removeEventListeners.bind(this)
		this.handleEvent = this.handleEvent.bind(this)
		this.handleEvents = this.handleEvents.bind(this)
	}

	componentDidMount() {
		this.addEventListeners()
		// TODO: set timer to remove events listeners if nothing happened
	}

	componentWillUnmount() {
		this.removeEventListeners()
	}

	addEventListeners() {
		window.addEventListener('touchstart', this.handleEvent)

		window.addEventListener('scroll', this.handleEvents)
		window.addEventListener('mousemove', this.handleEvents)
	}

	removeEventListeners() {
		window.removeEventListener('touchstart', this.handleEvent)

		window.removeEventListener('scroll', this.handleEvents)
		window.removeEventListener('mousemove', this.handleEvents)
	}

	handleEvent() {
		if (this.state.replaced) return
		this.removeEventListeners()
		this.setState(state => {
			state.replaced = true
			return state
		})
	}

	handleEvents() {
		if (this.state.replaced) return

		if (this.state.counter < this.props.threshold) {
			this.setState(state => {
				state.counter++
				return state
			})
		} else {
			this.removeEventListeners()
			this.setState(state => {
				state.replaced = true
				return state
			})
		}
	}

	render() {
		return (
			<React.Fragment>
				{this.props.render(this.state.replaced)}
			</React.Fragment>
		)
	}
}

HideFromBot.defaultProps = {
	threshold: 6,
}

export default HideFromBot
