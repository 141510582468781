import React from 'react'
import styled from 'styled-components'
import { FiCheck } from 'react-icons/fi'

export const List = styled.ul`
	list-style: ${props => (props.listStyle ? props.listStyle : 'none')};
	margin-left: ${props => props.theme.spacing.h.std};
	margin-bottom: 1.45rem;

	${props => {
		if (props.listStyle === 'ol') {
			return 'li { margin: 0; }'
		}
	}};
`

const ListItemBase = styled.li`
	margin: 0;
	display: flex;
`

const IconWrapper = styled.span`
	margin-right: 0.5rem;
	margin-top: 4px;
`

export const ListItemCheck = props => (
	<ListItemBase>
		<IconWrapper>
			<FiCheck />
		</IconWrapper>
		{props.children}
	</ListItemBase>
)
