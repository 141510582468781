import React from 'react'
import styled from 'styled-components'
import { Wrapper } from './Section'
import HideFromBot from '../components/HideFromBot'
import { MdLocationOn, MdSmartphone, MdEmail } from 'react-icons/md'

const Footer = styled.footer`
	padding-bottom: ${props => props.theme.spacing.v.smr};
	background-color: ${props => props.theme.color.black};
	color: ${props => props.theme.color.white};
`

const ContactItemBase = styled.div`
	font-size: 0.9rem;
	display: flex;
	align-items: center;
`

const ContactItem = props => {
	let Wrapper
	if (props.href) {
		Wrapper = styled(ContactItemBase.withComponent('a'))`
			text-decoration: none;
			color: inherit;
		`
	} else {
		Wrapper = ContactItemBase
	}

	return <Wrapper {...props} />
}

const ContactLabel = styled.span`
	display: inline-block;
	padding-left: 0.8rem;
	height: 21px;
`
const ContactPhone = props => {
	return (
		<HideFromBot
			render={replaced => {
				const phone = replaced ? props.data : null
				const tel = phone ? `tel:+549${phone}` : null

				return (
					<ContactItem href={tel}>
						<MdSmartphone />
						<ContactLabel>{phone}</ContactLabel>
					</ContactItem>
				)
			}}
		/>
	)
}

const ContactEmail = props => {
	return (
		<HideFromBot
			render={replaced => {
				const address = replaced
					? `${props.data[0]}@${props.data[1]}`
					: null
				const mailto = address ? `mailto:${address}` : null

				return (
					<ContactItem href={mailto}>
						<MdEmail />
						<ContactLabel>{address}</ContactLabel>
					</ContactItem>
				)
			}}
		/>
	)
}

const Copy = styled.div`
	opacity: 0.86;
	font-size: 0.8rem;
	text-align: center;
	padding-top: ${props => props.theme.spacing.v.std};
`

export default () => (
	<Footer>
		<Wrapper noBottomSpacing>
			<div>
				<h4>Contactanos</h4>
				<ContactItem href="https://goo.gl/maps/RXeGZKnQVVE2">
					<MdLocationOn />
					<ContactLabel>Av. Maipú 3480, Olivos, Bs. As.</ContactLabel>
				</ContactItem>
				<ContactPhone data="11 5414-4224" />
				<ContactEmail data={['info', '180consultora.com.ar']} />
			</div>
			<Copy>
				Copyright &copy; 2018 - 180º Consultora. Todos los derechos
				reservados.
			</Copy>
		</Wrapper>
	</Footer>
)
