import styled from 'styled-components'

export default styled.h2`
	text-transform: uppercase;
	margin-bottom: 4.6rem;
	display: flex;
	font-size: ${props => props.theme.typography.size.h2};
	font-weight: ${props => props.theme.typography.weight.h2};
	font-family: ${props => props.theme.typography.family.accent};
	justify-content: flex-start;

	@media (min-width: 490px) {
		justify-content: center;
	}
`
