import React from 'react'
import styled from 'styled-components'

import { Link } from 'gatsby'
import theme from '../utils/theme'
import logo from '../images/logo.svg'
import HideFromBot from '../components/HideFromBot'

import { FiX, FiMenu } from 'react-icons/fi'

const Nav = styled.nav`
	width: 100%;
	position: fixed;
	background: ${props => props.theme.color.bg};
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	transition: 0.25s ease-in-out;
	box-shadow: ${props =>
		props.detached ? '0 2px 12px 2px rgba(33, 37, 43, 0.3)' : 'none'};
	z-index: 99;
`

const Wrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: auto;

	max-width: ${props => props.theme.spacing.containerWidth};
	padding-left: ${props => props.theme.spacing.h.sm};
	padding-right: ${props => props.theme.spacing.h.sm};

	@media (min-width: ${props => props.theme.spacing.containerWidth}) {
		padding-left: 0;
		padding-right: 0;
	}
`

const Menu = styled.div`
	flex-direction: column;
	position: fixed;
	top: 0;
	right: 0;
	background: white;
	padding-top: ${props => props.theme.spacing.v.sm};
	padding-bottom: ${props => props.theme.spacing.v.std};
	padding-left: ${props => props.theme.spacing.h.std};
	padding-right: ${props => props.theme.spacing.h.std};
	height: 100%;
	width: 300px;
	box-shadow: 0 0 10px 0 rgba(33, 37, 43, 0.3);
	display: flex;
	transform: ${props => (props.show ? 'translateX(0)' : 'translateX(350px)')};
	transition: 0.25s ease-in-out;

	@media (min-width: ${props => props.theme.spacing.containerWidth}) {
		position: relative;
		display: grid;
		height: auto;
		width: auto;
		grid-auto-flow: column;
		grid-column-gap: 0.4rem;
		grid-template-rows: 1fr;
		padding: 0;
		box-shadow: none;
		transform: none;
	}
`

// const MenuCurtain = styled.div`
// 	position: absolute;
// 	left: 0;
// 	right: 0;
// 	top: 0;
// 	bottom: 0;
// 	background: rgba(0, 0, 0, 0.5);
// `

const MenuOpen = styled.button`
	border: none;
	background: none;
	margin: 0;
	padding: 12px;
	cursor: pointer;
	font-size: 2rem;
	display: flex;
	align-items: center;

	@media (min-width: ${props => props.theme.spacing.containerWidth}) {
		display: none;
	}
`

const MenuClose = styled.button`
	border: none;
	background: none;
	margin: 0;
	padding: 12px;
	cursor: pointer;
	font-size: 2rem;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	margin-bottom: ${props => props.theme.spacing.v.smr};

	@media (min-width: ${props => props.theme.spacing.containerWidth}) {
		display: none;
	}
`

const MenuOption = styled(Link)`
	text-transform: uppercase;
	text-decoration: none;
	font-size: 1.4rem;
	color: ${props => props.theme.color.fg};
	font-family: ${props => props.theme.typography.family.accent};
	padding: 8px 16px;
	margin-bottom: 0.5rem;

	@media (min-width: ${props => props.theme.spacing.containerWidth}) {
		font-size: 0.9rem;
		margin: 0;
		padding: 4px 8px;
	}
`

const MenuOptionAnchor = MenuOption.withComponent('a')

const activeStyle = {
	color: theme.color.black,
	backgroundColor: theme.color.accent,
	outline: 'none',
}

const MenuOptionCV = props => {
	return (
		<HideFromBot
			render={replaced => {
				const mailto = replaced
					? `mailto:${props.data[0]}@${
							props.data[1]
					  }?subject=Envío%20de%20CV`
					: null

				return (
					<MenuOptionAnchor href={mailto}>
						Enviá tu CV
					</MenuOptionAnchor>
				)
			}}
		/>
	)
}

const Logo = styled(Link)`
	padding-right: ${props => props.theme.spacing.h.sm};
`

const Img = styled.img`
	display: block;
	height: auto;
	margin-bottom: 0;
	max-width: 100%;
	max-height: 60px;

	@media (min-width: ${props => props.theme.spacing.containerWidth}) {
		max-height: 80px;
	}
`

export default class FinalNav extends React.Component {
	static defaultProps = { threshold: 0 }

	constructor(props) {
		super(props)

		this.state = {
			showMenu: false,
			navDetached: false,
		}

		this.toggleMenu = this.toggleMenu.bind(this)
		this.handleScroll = this.handleScroll.bind(this)
	}

	componentDidMount() {
		window.addEventListener('scroll', this.handleScroll)
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.handleScroll)
	}

	toggleMenu() {
		this.setState(state => {
			state.showMenu = !state.showMenu
			return state
		})
	}

	handleScroll(event) {
		this.setState(state => {
			const scrollPos = window.scrollY
			const threshold = this.props.threshold
			const detached = state.navDetached

			if (scrollPos > threshold && detached === false) {
				state.navDetached = true
			} else if (scrollPos <= threshold && detached === true) {
				state.navDetached = false
			}
			return state
		})
	}

	render() {
		return (
			<Nav detached={this.state.navDetached}>
				<Wrapper>
					<Logo to="/">
						<Img alt="" src={logo} />
					</Logo>
					<MenuOpen onClick={this.toggleMenu}>
						<FiMenu />
					</MenuOpen>
					{/* <MenuCurtain /> */}
					<Menu show={this.state.showMenu}>
						<MenuClose onClick={this.toggleMenu}>
							<FiX />
						</MenuClose>
						<MenuOption activeStyle={activeStyle} to="/">
							Inicio
						</MenuOption>
						<MenuOption
							activeStyle={activeStyle}
							to="/quienes-somos"
						>
							Quienes somos
						</MenuOption>
						<MenuOption activeStyle={activeStyle} to="/servicios">
							Servicios
						</MenuOption>
						<MenuOptionCV data={['cv', '180consultora.com.ar']} />
						<MenuOption activeStyle={activeStyle} to="/contacto">
							Contacto
						</MenuOption>
					</Menu>
				</Wrapper>
			</Nav>
		)
	}
}
