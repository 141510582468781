import styled from 'styled-components'

export default styled.h3`
	text-align: ${props => (props.center ? 'center' : 'left')};
	margin-bottom: 1.5rem;
	font-size: ${props => props.theme.typography.size.h3};
	font-weight: ${props => props.theme.typography.weight.h3};
	font-family: ${props => props.theme.typography.family.accent};

	@media (min-width: 900px) {
		margin-bottom: 3rem;
	}
`
