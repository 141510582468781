import React from 'react'
import styled from 'styled-components'

const Section = styled.section`
	text-align: ${props => (props.cta ? 'center' : 'left')};
	background-color: ${props =>
		props.cta ? props.theme.color.accent : props.theme.color.bg};
`

export const Wrapper = styled.div`
	margin: auto;

	padding-top: ${props =>
		props.noVerticalSpacing ? 0 : props.theme.spacing.v.smr};
	padding-bottom: ${props =>
		props.noVerticalSpacing || props.noBottomSpacing
			? 0
			: props.theme.spacing.v.smr};
	padding-left: ${props => (props.wide ? 0 : props.theme.spacing.h.sm)};
	padding-right: ${props => (props.wide ? 0 : props.theme.spacing.h.sm)};

	max-width: ${props =>
		props.wide ? '100%' : props.theme.spacing.containerWidth};

	@media (min-width: ${props => props.theme.spacing.containerWidth}) {
		padding-top: ${props =>
			props.noVerticalSpacing ? 0 : props.theme.spacing.v.std};
		padding-bottom: ${props =>
			props.noVerticalSpacing || props.noBottomSpacing
				? 0
				: props.theme.spacing.v.std};
		padding-left: 0;
		padding-right: 0;
	}
`

export default props => (
	<Section {...props}>
		<Wrapper {...props}>{props.children}</Wrapper>
	</Section>
)
