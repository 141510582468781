const color = {
	white: '#fff',
	black: '#000',
	tan: '#D9BD8F',
	tuscan: '#f2d0a4',
	blue: '#0C7CD7',
	grey: '#83817E',
	greyDark: '#575757',
	greyLight: '#C9C9C9',
	lightBlue: '#9BAFBD',
}

color.accent = color.tan
color.accentAlpha = 'rgba(217, 189, 143, 0.8)'
color.blackAlpha = 'rgba(0, 0, 0, 0.6)'
color.fg = color.black
color.bg = color.white

const spacingVStd = 64
const spacingHStd = 32

export const fontSizeBase = 14
export const fontSans = [
	'Open Sans',
	'Avenir Next',
	'Helvetica Neue',
	'Segoe UI',
	'Helvetica',
	'Arial',
	'sans-serif',
]
export const fontAccent = ['Raleway', ...fontSans]

export default {
	color,
	typography: {
		family: {
			accent: fontAccent.map(f => `"${f}"`).join(','),
			base: fontSans.map(f => `"${f}"`).join(','),
		},
		size: {
			base: `${fontSizeBase}px`,
			h1: `${fontSizeBase * 2.6}px`,
			h2: `${fontSizeBase * 1.9}px`,
			h3: `${fontSizeBase * 1.2}px`,
			sm: `${fontSizeBase * 0.9}px`,
		},
		weight: {
			base: 'normal',
			h1: 300,
			h2: 300,
			h3: 300,
		},
	},
	spacing: {
		containerWidth: '1000px',
		v: {
			lrgr: `${spacingVStd * 1.4}px`,
			lrg: `${spacingVStd * 1.2}px`,
			std: `${spacingVStd}px`,
			sm: `${spacingVStd * 0.6}px`,
			smr: `${spacingVStd * 0.4}px`,
		},
		h: {
			std: `${spacingHStd}px`,
			sm: `${spacingHStd * 0.6}px`,
			smr: `${spacingHStd * 0.4}px`,
		},
	},
	btn: {
		radius: '2px',
		borderWidth: '2px',
		padding: '6px 16px',
		paddingMed: '10px 20px',
		paddingBig: '12px 24px',
		paddingSm: '4px 8px',
		fontSize: '1rem',
		fontSizeMed: '1.2rem',
		fontSizeBig: '1.4rem',
		accent: {
			ghost: {
				fg: color.accent,
				bg: color.white,
			},
			flat: {
				fg: color.black,
				bg: color.accent,
			},
			link: {
				fg: color.accent,
			},
		},
		primary: {
			ghost: {
				fg: color.black,
			},
			flat: {
				fg: color.accent,
				bg: color.black,
			},
			link: {
				fg: color.blue,
			},
		},
		default: {
			ghost: {
				fg: color.greyDark,
				bg: color.white,
			},
			flat: {
				fg: color.white,
				bg: color.grey,
			},
			link: {
				fg: color.grey,
			},
		},
	},
}
