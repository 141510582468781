import React from 'react'
import Helmet from 'react-helmet'
import styled from 'styled-components'

import Document from '../layouts/Document'
import ContainerWrapper from '../layouts/ContainerWrapper'
import Header from '../layouts/Header'
import Section from '../layouts/Section'
import SectionTitle from '../layouts/SectionTitle'
import SectionSubTitle from '../layouts/SectionSubTitle'

import Button from '../components/Button'
import Text from '../components/Text'
import { Hero, HeroList, HeroListItem, HeroAction } from '../components/Hero'

import {
	FiArrowRight,
	FiSearch,
	FiUserCheck,
	FiTrendingUp,
} from 'react-icons/fi'

const Services = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: ${props => props.theme.spacing.v.sm};

	@media (min-width: 770px) {
		grid-template-columns: 1fr 1fr 1fr;
		grid-gap: 6%;
	}
`

const Service = styled.div`
	display: flex;
	flex-direction: column;
`

const ServiceTitle = props => {
	const PreIcon = props.preIcon || 'span'
	const PostIcon = props.postIcon || 'span'

	const marginRight = props.postIcon ? '1rem' : 0
	const marginLeft = props.preIcon ? '1rem' : 0

	const titleStyle = { marginLeft, marginRight }
	const iconStyle = { fontSize: '2rem', display: 'flex' }

	return (
		<SectionSubTitle style={{ display: 'flex', alignItems: 'center' }}>
			<div style={iconStyle}>
				<PreIcon />
			</div>
			<span style={titleStyle}>{props.title}</span>
			<div style={iconStyle}>
				<PostIcon />
			</div>
		</SectionSubTitle>
	)
}

const ServiceBody = styled(Text)`
	flex: 1;
`

const ServiceCTA = styled.div`
	text-align: right;
	padding-top: ${props => props.theme.spacing.v.smr};

	@media (min-width: 770px) {
		padding-top: ${props => props.theme.spacing.v.sm};
	}
`

export default () => (
	<Document>
		<Helmet>
			<title>Inicio</title>
			<meta
				name="description"
				content="Nos especializamos en Recursos Humanos y gestión de procesos. Somos un equipo de profesionales con amplia experiencia en el mercado."
			/>
		</Helmet>
		<ContainerWrapper noVerticalSpacing>
			<Header noVerticalSpacing>
				<Hero home>
					<HeroList>
						<HeroListItem>
							Nos especializamos en Recursos Humanos y gestión de
							procesos.
						</HeroListItem>
						<HeroListItem>
							Somos un equipo de profesionales con amplia
							experiencia en el mercado.
						</HeroListItem>
						<HeroListItem>
							Acompañamos a nuestros clientes en la gestión
							integral de los Recursos Humanos.
						</HeroListItem>
						<HeroListItem>
							Trabajamos en forma personalizada en cada proyecto,
							asumiendo cada desafío con pasión y responsabilidad.
						</HeroListItem>
					</HeroList>
					<HeroAction>
						<Button
							link
							flat
							accent
							big
							href="/quienes-somos"
							postIcon={FiArrowRight}
						>
							Conocenos
						</Button>
					</HeroAction>
				</Hero>
			</Header>
			<Section>
				<SectionTitle>Servicios</SectionTitle>
				<Services>
					<Service>
						<ServiceTitle
							preIcon={FiSearch}
							title="Búsqueda y selección de personal"
						/>
						<ServiceBody>
							Contamos con un equipo de trabajo especializado en
							el reclutamiento y la selección de perfiles idóneos.
						</ServiceBody>
						<ServiceCTA>
							<Button
								link
								flat
								accent
								href="/servicios#seleccion-de-persona"
								postIcon={FiArrowRight}
							>
								Leer más
							</Button>
						</ServiceCTA>
					</Service>
					<Service>
						<ServiceTitle
							preIcon={FiUserCheck}
							title="Evaluaciones"
						/>
						<ServiceBody>
							Realizamos diferentes técnicas para evaluar
							candidatos (selección de personal), también personal
							que este trabajando dentro de la organización
							(detección de necesidades de capacitación,
							desarrollo de carrera, medición de potencial, entre
							otras).
						</ServiceBody>
						<ServiceCTA>
							<Button
								link
								flat
								accent
								href="/servicios#evaluaciones"
								postIcon={FiArrowRight}
							>
								Leer más
							</Button>
						</ServiceCTA>
					</Service>
					<Service>
						<ServiceTitle
							preIcon={FiTrendingUp}
							title="Start UP RRHH"
						/>
						<ServiceBody>
							Ofrecemos este servicios para clientes que aún no
							hayan desarrollado el área de RRHH o bien estén
							iniciando sus actividades en el mercado.
						</ServiceBody>
						<ServiceCTA>
							<Button
								link
								flat
								accent
								href="/servicios#startup"
								postIcon={FiArrowRight}
							>
								Leer más
							</Button>
						</ServiceCTA>
					</Service>
				</Services>
			</Section>
			<Section cta>
				<SectionSubTitle center>
					Si estás interesado en participar de nuestras búsquedas
				</SectionSubTitle>
				<Text dense align="center">
					<Button
						link
						flat
						primary
						href="mailto:cv@180consultora.com.ar?subject=Envío%20de%20CV"
						postIcon={FiArrowRight}
					>
						Enviá tu CV
					</Button>
				</Text>
			</Section>
		</ContainerWrapper>
	</Document>
)
