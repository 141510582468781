import React from 'react'
import styled, { keyframes } from 'styled-components'
import { Formik, Field } from 'formik'
import { FiSend, FiLoader, FiCheckCircle } from 'react-icons/fi'
import Button from '../components/Button'
import 'isomorphic-fetch'

const FormWrapper = styled.div`
	position: relative;
	display: grid;
	max-width: 600px;
	margin: auto;
	padding-top: ${props => props.theme.spacing.v.std};
	grid-gap: ${props => props.theme.spacing.v.sm};
	grid-template-areas:
		'full_name'
		'email'
		'phone'
		'company'
		'message'
		'submit	';

	@media (min-width: 550px) {
		grid-template-areas:
			'full_name	full_name'
			'email	email'
			'company	phone'
			'message	message'
			'submit		submit';
	}

	label {
		font-size: ${props => props.theme.typography.size.sm};
	}

	input,
	textarea {
		border: none;
		border-bottom: 2px solid ${props => props.theme.color.greyLight};
		background: none;
		padding: 8px;

		&:focus {
			border-bottom-color: ${props => props.theme.color.accent};
		}
	}
`

const FormPart = styled.div`
	grid-area: ${props => props.area};
	display: flex;
	flex-direction: column;
	align-items: ${props => (props.area === 'submit' ? 'flex-end' : 'stretch')};
`

const FText = styled(Field).attrs({ type: 'text' })`
	outline: none;
`

const FTextArea = styled(Field).attrs({ component: 'textarea' })`
	height: 150px;
	outline: none;
`

const ValidationError = styled.div`
	font-size: 0.9rem;
	color: #d50000;
`

const SubLabel = styled.span`
	opacity: 0.6;
	font-size: 0.9em;
	padding-left: 0.5em;
`

const rotate360 = keyframes`
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
`

const Loading = styled(FiLoader)`
	animation: ${rotate360} 2s linear infinite;
`

const Success = styled.div`
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background-color: rgb(255, 255, 255, 0.86);
	display: flex;
	justify-content: center;
	z-index: 9;

	& > div {
		padding-top: ${props => props.theme.spacing.v.lrgr};
		text-align: center;
	}
`

function encode(data) {
	return Object.keys(data)
		.map(
			key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
		)
		.join('&')
}

export default class ContactForm extends React.Component {
	state = {
		sent: false,
	}

	handleSubmit = (values, { setSubmitting }) => {
		values['form-name'] = 'contact'
		values['subject'] = 'Respuesta de 180º Consultora'

		return fetch('/', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
			body: encode({
				...values,
			}),
		})
			.then(() => {
				setSubmitting(false)
				this.setState({ sent: true })
			})
			.catch(error => alert(error))
	}

	render() {
		return (
			<Formik
				onSubmit={this.handleSubmit}
				initialValues={{
					full_name: '',
					email: '',
					company: '',
					phone: '',
					message: '',
				}}
				validate={values => {
					let errors = {}

					// full_name
					if (!values.full_name) {
						errors.full_name = 'Requerido'
					} else if (values.full_name.length < 3) {
						errors.full_name = 'Al menos 3 caracteres'
					}

					// email
					if (!values.email) {
						errors.email = 'Requerido'
					} else if (
						!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
							values.email
						)
					) {
						errors.email = 'Dirección inválida'
					}

					// message
					if (!values.message) {
						errors.message = 'Requerido'
					} else if (values.message.length < 10) {
						errors.message = 'Al menos 10 caracteres'
					}

					return errors
				}}
			>
				{({
					isSubmitting,
					errors,
					touched,
					handleSubmit,
					handleChange,
				}) => {
					let success

					if (this.state.sent) {
						success = (
							<Success>
								<div>
									<h2>
										<span
											style={{
												color: '#04ad04',
												paddingRight: '1rem',
											}}
										>
											<FiCheckCircle />
										</span>
										¡Gracias por por escribirnos!
									</h2>
									<p>
										Nos pondremos en contacto a la brevedad.
									</p>
								</div>
							</Success>
						)
					}
					return (
						<form
							data-netlify="true"
							data-netlify-honeypot="bot-field"
							name="contact"
							method="post"
							onSubmit={handleSubmit}
						>
							<FormWrapper>
								{success}
								{/* The `form-name` hidden field is required to support form submissions without JavaScript */}
								<input
									type="hidden"
									name="form-name"
									value="contact"
								/>
								<input
									type="hidden"
									name="subject"
									value="Respuesta de 180º Consultora"
								/>
								<p hidden>
									<label>
										No completar!
										<input
											name="bot-field"
											onChange={handleChange}
										/>
									</label>
								</p>
								<FormPart area="full_name">
									<label htmlFor="full_name">
										Nombre completo
									</label>
									<FText id="full_name" name="full_name" />
									{errors.full_name &&
										touched.full_name && (
											<ValidationError>
												{errors.full_name}
											</ValidationError>
										)}
								</FormPart>

								<FormPart area="email">
									<label htmlFor="email">Email</label>
									<FText
										id="email"
										name="email"
										type="email"
									/>
									{errors.email &&
										touched.email && (
											<ValidationError>
												{errors.email}
											</ValidationError>
										)}
								</FormPart>

								<FormPart area="company">
									<label htmlFor="company">
										Empresa <SubLabel>(opcional)</SubLabel>
									</label>
									<FText id="company" name="company" />
								</FormPart>

								<FormPart area="phone">
									<label htmlFor="phone">
										Teléfono <SubLabel>(opcional)</SubLabel>
									</label>
									<FText id="phone" name="phone" />
								</FormPart>

								<FormPart area="message">
									<label htmlFor="message">Mensaje</label>
									<FTextArea id="message" name="message" />
									{errors.message &&
										touched.message && (
											<ValidationError>
												{errors.message}
											</ValidationError>
										)}
								</FormPart>
								<div data-netlify-recaptcha="true" />
								<FormPart area="submit">
									<Button
										flat
										accent
										type="submit"
										postIcon={
											isSubmitting ? Loading : FiSend
										}
										disabled={isSubmitting}
									>
										{isSubmitting
											? 'Enviando...'
											: 'Enviar'}
									</Button>
								</FormPart>
							</FormWrapper>
						</form>
					)
				}}
			</Formik>
		)
	}
}
