import React from 'react'
import Helmet from 'react-helmet'

import Document from '../layouts/Document'
import ContainerWrapper from '../layouts/ContainerWrapper'
import Header from '../layouts/Header'
import PageTitle from '../layouts/PageTitle'
import Section from '../layouts/Section'
import SectionSubTitle from '../layouts/SectionSubTitle'

import { Hero } from '../components/Hero'
import ContactFrom from '../components/ContactForm'

export default () => (
	<Document>
		<Helmet>
			<title>Contacto</title>
			<meta name="description" content="Dejanos tu mensaje y a la brevedad nos estaremos poniendo en contacto." />
		</Helmet>
		<ContainerWrapper>
			<Header>
				<Hero>
					<PageTitle>Contacto</PageTitle>
				</Hero>
			</Header>

			<Section>
				<SectionSubTitle>
					Dejanos tu mensaje y a la brevedad nos estaremos poniendo en
					contacto.
				</SectionSubTitle>
				<ContactFrom />
			</Section>
		</ContainerWrapper>
	</Document>
)
