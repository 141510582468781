import React from 'react'
import Helmet from 'react-helmet'

import styledNormalize from 'styled-normalize'
import styled, { injectGlobal, ThemeProvider } from 'styled-components'

import theme from '../utils/theme'

import Page from './Page'
import Container from './Container'
import Nav from './Nav'
import Footer from './Footer'

// import favicon from '../images/favicon.png'

injectGlobal`
	${styledNormalize}

	* {
		box-sizing: border-box;
	}

	html,
	body {
		height: 100%;
	}

	html {
		font-size: ${theme.typography.size.base};
		line-height: 1.5em;
	}

	body {
		font-family: ${theme.typography.family.base};
		font-weight: normal;
		word-wrap: break-word;
		font-kerning: normal;
		-moz-font-feature-settings: "kern", "liga", "clig", "calt";
		-ms-font-feature-settings: "kern", "liga", "clig", "calt";
		-webkit-font-feature-settings: "kern", "liga", "clig", "calt";
		font-feature-settings: "kern", "liga", "clig", "calt";
	}

	h1,
	h2,
	h3,
	h4,
	p {
		margin-top: 0;
		text-rendering: optimizeLegibility;
	}

	h1,
	h2,
	h3,
	h4 {
		line-height: 1.1;
	}

	ul {
		padding: 0;
	}
`

const Wrapper = styled.div`
	padding-top: calc(73px + 1rem);

	@media (min-width: ${props => props.theme.spacing.containerWidth}) {
		padding-top: calc(93px + 1rem);
	}
`

const Document = props => (
	<React.Fragment>
		<Helmet
			defaultTitle={'180º Consultora'}
			titleTemplate={'%s - 180º Consultora'}
		>
			<html lang="es" />
			<meta
				name="viewport"
				content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
			/>
		</Helmet>
		<ThemeProvider theme={theme}>
			<Page>
				<Container>
					<Nav />
					<Wrapper>
						{props.children}
						<Footer />
					</Wrapper>
				</Container>
			</Page>
		</ThemeProvider>
	</React.Fragment>
)

export default Document
