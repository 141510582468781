import styled from 'styled-components'

export default styled.h1`
	text-transform: uppercase;
	text-align: center;
	margin-bottom: 3rem;
	font-size: ${props => props.theme.typography.size.h1};
	font-weight: ${props => props.theme.typography.weight.h1};
	font-family: ${props => props.theme.typography.family.accent};
`
