import React from 'react'
import Helmet from 'react-helmet'
import styled from 'styled-components'

import Document from '../layouts/Document'
import ContainerWrapper from '../layouts/ContainerWrapper'
import Header from '../layouts/Header'
import PageTitle from '../layouts/PageTitle'
import Section from '../layouts/Section'
import SectionTitle from '../layouts/SectionTitle'

import { Hero } from '../components/Hero'
import { List, ListItemCheck } from '../components/List'
import Text from '../components/Text'

const Grid3 = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: ${props => props.theme.spacing.v.sm};

	@media (min-width: 770px) {
		grid-template-columns: 1fr 1fr 1fr;
		grid-gap: 6%;
	}
`

export default () => (
	<Document>
		<Helmet>
			<title>Quiénes Somos</title>
			<meta name="description" content="Nos especializamos en Recursos Humanos y gestión de procesos. Somos un equipo de profesionales con amplia experiencia en el mercado." />
		</Helmet>
		<ContainerWrapper>
			<Header>
				<Hero>
					<PageTitle>Quienes Somos</PageTitle>
				</Hero>
			</Header>
			<Section>
				<Grid3>
					<div>
						<SectionTitle>Misión</SectionTitle>
						<Text>
							Participar en el progreso de nuestros clientes
							brindando soluciones adecuadas, acompañándolos en la
							gestión del capital humano.
						</Text>
					</div>
					<div>
						<SectionTitle>Visión</SectionTitle>
						<Text>
							Somos socios estratégicos de nuestros clientes y en
							este sentido trabajamos en equipo, alineandonos a
							sus objetivos, aportando servicios y soluciones.
						</Text>
					</div>
					<div>
						<SectionTitle>Valores</SectionTitle>
						<List>
							<ListItemCheck>Mejora continua</ListItemCheck>
							<ListItemCheck>Calidad de servicios</ListItemCheck>
							<ListItemCheck>
								Trabajo en equipo junto a nuestros clientes
							</ListItemCheck>
							<ListItemCheck>Flexibiliad</ListItemCheck>
							<ListItemCheck>
								Trabajo y atención personalizada
							</ListItemCheck>
							<ListItemCheck>
								Transparencia y compromiso en cada uno de
								nuestros procesos
							</ListItemCheck>
						</List>
					</div>
				</Grid3>
			</Section>
		</ContainerWrapper>
	</Document>
)
