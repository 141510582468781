import React from 'react'

const Icon = props => {
	const IconReceived = props.pre || props.post

	const marginRight = props.pre ? '1rem' : 0
	const marginLeft = props.post ? '1rem' : 0

	const spanStyle = { marginLeft, marginRight, display: 'flex' }

	return (
		<span style={spanStyle}>
			<IconReceived />
		</span>
	)
}

export default Icon
